
// ------------------- Collapse and expand side Menu ------------------//
function MinimizeSideMenu() {
  $(".SideMenuLabel").hide();
  $(".SideMenuToggleIcon.CollapseArrow").hide();
  $(".SideMenuToggleIcon.ExpandArrow").show();
  $('.SideMenuContainer').css('width', '50px');
}

function MaximizeSidebar() {
  $(".SideMenuLabel").fadeIn();
  $(".SideMenuToggleIcon.CollapseArrow").fadeIn();
  $(".SideMenuToggleIcon.ExpandArrow").hide();
  $('.SideMenuContainer').css('width', '225px');
}

// ------------------- Show or hide panel ------------------//
function ShowPanel(PanelName) {
  var x = PanelName.id;
  var y = $("#" + x).attr("data-panel-type");
  $(".PanelContainer").hide();
  $("#" + y + "Panel").fadeIn();
  $(".SideMenuTab").removeClass('Active');
  $(".SearchResultsTab").removeClass('Active');
  $("#" + y + "Tab").addClass('Active');
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  $(".PanelDrawer").hide();
}

function HideGlobalDrawer() {
  if ($(window).width() < 767) {
    $(".GlobalPanelDrawer").animate({
      right: '-100%'
    }, 200);
    $(".GlobalPanelDrawer").fadeOut();
    $("body").css('overflow', 'auto');
  } else {
    $(".GlobalPanelDrawer").animate({
      right: '-60%'
    }, 200);
    $(".GlobalPanelDrawer").fadeOut();
    $("body").css('overflow', 'auto');
  }
  $(".PageMask").hide();
}

function ShowDrawer() {
  $(".PanelDrawer").show();
  $(".PanelDrawer").animate({
    right: '0px'
  }, 200);
  $("body").css('overflow', 'hidden');
  $(".PageMask").show();
  $(".PanelDrawer").scrollTop(0);
}

function HideDrawer() {
  if ($(window).width() < 767) {
    $(".PanelDrawer").animate({
      right: '-100%'
    }, 200);
    $(".PanelDrawer").fadeOut();
    $("body").css('overflow', 'auto');
  } else {
    $(".PanelDrawer").animate({
      right: '-60%'
    }, 200);
    $(".PanelDrawer").fadeOut();
    $("body").css('overflow', 'auto');
  }
  $(".PageMask").hide();
}

function ShowLayeredDrawer() {
  $(".LayeredPanelDrawer").show();
  $(".LayeredPanelDrawer").animate({
    right: '0px'
  }, 200);
  $(".LayeredPanelDrawer").scrollTop(0);
}

function HideLayeredDrawer() {
  if ($(window).width() < 767) {
    $(".LayeredPanelDrawer").animate({
      right: '-100%'
    }, 200);
    $(".LayeredPanelDrawer").fadeOut();
  } else {
    $(".LayeredPanelDrawer").animate({
      right: '-60%'
    }, 200);
    $(".LayeredPanelDrawer").fadeOut();
  }
}

function ShowDrawerSection(DrawerSectionName) {
  $(".DrawerSubSection").hide();
  $("#" + DrawerSectionName + "DrawerSection").fadeIn();
}

function ShowDrawerSegment(DrawerSegmentName) {
  $(".DrawerSegmentContainer").hide();
  $("#" + DrawerSegmentName + "DrawerSegment").show();
}

// ------------------- Disable inputs ------------------//
$(document).ready(function() {
  $('.DisableInput').find(":input").attr('tabindex', '-1');
});

// ------------------- Prevent child elements clicks from propagating to their parents ------------------//
function ExcludeParentBubbling(event) {
  event.stopPropagation();
}

// ------------------- Toggle password visaibilty ------------------//
function togglePasswordVisibility(Selected) {
  var y = $(Selected).siblings("input").attr("id");
  if ($("#" + y).attr("type") == "password") {
    $("#" + y).attr("type", "text");
    $(".TogglePasswordVisibility").toggleClass("VisibleIcon HiddenIcon");
  } else {
    $("#" + y).attr("type", "password");
    $(".TogglePasswordVisibility").toggleClass("VisibleIcon HiddenIcon");
  }
}

function ShowFilters() {
  $(".FiltersContainer").animate({right: '0px'}, 100);
  $(".ShowFilters").hide();
  $(".HideFilters").show();
}

function HideFilters() {
  $(".FiltersContainer").animate({right: '-350px'}, 100);
  $(".HideFilters").hide();
  $(".ShowFilters").show();
}

var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
};

var panelValue = getUrlParameter('filter');

$(window).bind("load", function() {
  if (panelValue != null) {
    $(".PanelContainer").hide();
    $("#" + panelValue + "Panel").fadeIn();
    $(".SideMenuTab").removeClass('Active');
    $(".SearchResultsTab").removeClass('Active');
    $("#" + panelValue + "Tab").addClass('Active');
    $(".PanelDrawer").hide();
  }
});
